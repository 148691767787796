/* eslint-disable sonarjs/no-duplicate-string */
import { useParams } from 'react-router-dom';
import { usePermissions, useSeller } from 'hooks';
import envs from 'envs';
import PropTypes from 'prop-types';
import React from 'react';

// Components
import { Mui } from 'components';
import { NavBar as DefaultNavBar } from 'components/layouts';

export const useStyles = Mui.makeStyles(theme => ({
  chipAlert: {
    color: theme.palette?.error?.contrastText,
    backgroundColor: theme.palette?.error?.main,
  },
}));

function NavBar({ onMobileClose, openMobile, sellerPermissions, ...restProps }) {
  // Hooks
  const { sellerSlug } = useParams();
  const { permissions } = usePermissions();
  const { seller } = useSeller();
  const classes = useStyles();

  // Constants
  const { isInstitution, isStaff } = permissions || {};
  const {
    logo: image,
    cover,
    displayName,
    taxDocument: info,
    institution,
    pendingTasks,
  } = seller || {};
  const { slug: institutionSlug = null, logo: institutionLogo = null } = institution || {};
  const isSellerInstitution = !!seller && !!seller.institution;

  // Props
  const profile = { image, cover, displayName, profileUrl: '/sellers', info };

  const sellerPath = `/sellers/${sellerSlug}`;
  const items = [
    {
      subheader: 'Sua conta',
      items: [
        {
          title: 'Ir para instituição',
          href: ['/institutions', institutionSlug].join('/'),
          avatar: newProps => <Mui.Avatar src={institutionLogo} {...newProps} />,
          invisible: !isInstitution || !institutionSlug,
        },
        {
          title: 'Início',
          href: sellerPath,
          icon: newProps => <Mui.Icon {...newProps}>home_outlined</Mui.Icon>,
        },
        {
          title: 'Perfil',
          href: [sellerPath, 'profile'].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>business</Mui.Icon>,
          invisible: !sellerPermissions?.includes('account.profile'),
        },
        {
          title: 'Cobrança',
          icon: newProps => <Mui.Icon {...newProps}>receipt_long</Mui.Icon>,
          items: [
            { title: 'Planos', href: [sellerPath, 'billing', 'plan'].join('/') },
            { title: 'Faturas', href: [sellerPath, 'billing', 'invoices'].join('/') },
            {
              title: 'Cartões de Crédito',
              href: [sellerPath, 'billing', 'credit-cards'].join('/'),
            },
            {
              title: 'Contas Bancárias',
              href: [sellerPath, 'billing', 'bank-accounts'].join('/'),
              invisible: !sellerPermissions?.includes('account.bank_accounts'),
            },
          ].filter(item => !item.invisible),
          invisible:
            !sellerPermissions?.includes('billingmanager.billingmanager') ||
            sellerSlug === envs.APPLICATION_SLUG,
        },
      ].filter(item => !item.invisible),
    },
    {
      subheader: 'Eskolare Insights',
      items: [
        {
          title: 'Indicadores',
          icon: newProps => <Mui.Icon {...newProps}>dashboard</Mui.Icon>,
          items: [
            { title: 'Geral', href: [sellerPath, 'indicators', 'general'].join('/') },
            { title: 'Pedidos', href: [sellerPath, 'indicators', 'orders'].join('/') },
            { title: 'Financeiro', href: [sellerPath, 'indicators', 'financial'].join('/') },
            {
              title: 'Carrinhos Abandonados',
              href: [sellerPath, 'indicators', 'abandoned-carts'].join('/'),
            },
            { title: 'Eskolare Desk', href: [sellerPath, 'indicators', 'eskolare-desk'].join('/') },
            {
              title: 'Acordos de Vendas',
              href: [sellerPath, 'indicators', 'sales-agreement'].join('/'),
            },
          ],
        },
        {
          title: 'Carrinhos Abandonados',
          href: [sellerPath, 'abandoned-carts'].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>remove_shopping_cart</Mui.Icon>,
          invisible: !sellerPermissions?.includes('cx.baskets'),
        },
        {
          title: 'Avaliações de usuários',
          href: [sellerPath, 'nps-score'].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>reviews</Mui.Icon>,
        },
        {
          title: 'Configurações',
          icon: newProps => <Mui.Icon {...newProps}>settings</Mui.Icon>,
          items: [{ title: 'Tags', href: [sellerPath, 'tags'].join('/') }],
        },
      ].filter(item => !item.invisible),
      invisible: !sellerPermissions?.includes('analytics.dashboards'),
    },
    {
      subheader: 'Pessoas e Acesso',
      items: [
        {
          title: 'Grupos',
          href: [sellerPath, 'groups'].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>supervised_user_circle_icon</Mui.Icon>,
          invisible: !sellerPermissions?.includes('permissions.groups'),
        },
        {
          title: 'Usuários',
          href: [sellerPath, 'users'].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>account_circle</Mui.Icon>,
          invisible: !sellerPermissions?.includes('permissions.users'),
        },
      ].filter(item => !item.invisible),
    },
    {
      subheader: 'Suporte da Plataforma',
      items: [
        {
          title: 'Atendimentos',
          href: [sellerPath, 'support', 'platform-tickets'].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>question_answer</Mui.Icon>,
          invisible: sellerSlug === envs.APPLICATION_SLUG,
        },
        {
          title: 'Artigos de ajuda',
          href: [sellerPath, 'support', 'platform-articles'].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>help</Mui.Icon>,
        },
      ].filter(item => !item.invisible),
    },
    {
      subheader: 'Suporte aos Clientes',
      items: [
        {
          title: 'Chamados',
          href: [sellerPath, 'support', 'tickets'].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>support_agent</Mui.Icon>,
          invisible:
            !sellerPermissions?.includes('support.can_manage_tickets') &&
            !sellerPermissions?.includes('support.can_view_tickets'),
        },
        {
          title: 'Configurações',
          href: [sellerPath, 'support', 'configuration'].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>settings_applications_icon</Mui.Icon>,
          invisible: !sellerPermissions?.includes('support.configurations'),
        },
      ].filter(item => !item.invisible),
    },
    {
      subheader: 'Gestão',
      items: [
        {
          title: 'Pendências',
          href: [sellerPath, 'tasks'].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>railway_alert</Mui.Icon>,
          info: () =>
            pendingTasks ? (
              <Mui.Chip size="small" label={pendingTasks} className={classes.chipAlert} />
            ) : null,
          invisible: !sellerPermissions?.includes('tasks.tasks'),
        },
        {
          title: 'Pedidos',
          href: [sellerPath, 'orders'].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>check_box_outlined</Mui.Icon>,
          invisible: !sellerPermissions?.includes('orders.orders'),
        },
        {
          title: 'Notas Fiscais',
          href: [sellerPath, 'tax-invoices'].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>receipt</Mui.Icon>,
          invisible: !isStaff || !sellerPermissions?.includes('tax_invoice.configurations'),
          isNew: true,
          isStaff: true,
        },
        {
          title: 'Entrega na instituição',
          href: [sellerPath, 'institution-shipment'].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>home_work</Mui.Icon>,
          invisible: !sellerPermissions?.includes('logistics.institution_shipments'),
        },
        {
          title: 'Acordos de vendas',
          href: [sellerPath, 'sales-agreements'].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>handshake</Mui.Icon>,
          invisible:
            !sellerPermissions?.includes('sale_agreement.can_view_sale_agreement') &&
            !sellerPermissions?.includes('sale_agreement.can_manage_sale_agreement'),
        },
        {
          title: 'Financeiro',
          href: [sellerPath, 'financial'].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>attach_money_outlined</Mui.Icon>,
          invisible:
            !sellerPermissions?.includes('financial.commissions') &&
            !sellerPermissions?.includes('financial.statement') &&
            !sellerPermissions?.includes('financial.can_manage_commission_payable') &&
            !sellerPermissions?.includes('financial.can_manage_commission_receivable'),
          items: [
            {
              title: 'Conta-corrente',
              href: [sellerPath, 'financial'].join('/'),
              invisible: !sellerPermissions?.includes('financial.statement'),
            },
            {
              title: 'Taxas e Comissões',
              href: [sellerPath, 'financial/fees'].join('/'),
              invisible: !isStaff || !sellerPermissions?.includes('financial.commissions'),
            },
            {
              title: 'Contas de comissão',
              href: [sellerPath, 'financial/sales-agreements-commission-accounts'].join('/'),
              invisible:
                !sellerPermissions?.includes('financial.can_manage_commission_payable') &&
                !sellerPermissions?.includes('financial.can_manage_commission_receivable'),
              isNew: true,
            },
            {
              title: 'Informe de Saldo e Comissões',
              href: [sellerPath, `financial/income-report/${new Date().getFullYear() - 1}`].join(
                '/',
              ),
              invisible: !sellerPermissions?.includes('financial.statement'),
            },
          ].filter(item => !item.invisible),
        },
        {
          title: 'Pagamentos',
          href: [sellerPath, 'payments'].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>payment</Mui.Icon>,
          invisible: !sellerPermissions?.includes('payments.payments'),
        },
        {
          title: 'Tenho interesse',
          href: [sellerPath, 'product-alerts'].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>notifications_active</Mui.Icon>,
          invisible: !sellerPermissions?.includes('catalog.product_alerts'),
        },
        {
          title: 'Alertas de estoque',
          href: [sellerPath, 'stock-alerts'].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>warning</Mui.Icon>,
          invisible: !sellerPermissions?.includes('logistics.stock_alerts'),
        },
      ].filter(item => !item.invisible),
    },
    {
      subheader: 'Gestão Escolar',
      items: [
        {
          title: 'Instalação',
          href: [sellerPath, 'school-management', 'installation-guide'].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>ballot</Mui.Icon>,
          invisible: isSellerInstitution || !isStaff,
          isStaff: true,
        },
        {
          title: 'Alunos',
          href: [sellerPath, 'school-management', 'institution-students'].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>face</Mui.Icon>,
          isStaff: true,
          invisible:
            (!sellerPermissions?.includes('school_management.can_manage_students') &&
              !sellerPermissions?.includes('school_management.can_view_students')) ||
            !isSellerInstitution ||
            !isStaff,
        },
        {
          title: 'Matrículas',
          href: [sellerPath, 'school-management', 'enrollments'].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>recent_actors</Mui.Icon>,
          invisible:
            (!sellerPermissions?.includes('school_management.can_manage_students') &&
              !sellerPermissions?.includes('school_management.can_view_students')) ||
            !isSellerInstitution,
        },
        {
          title: 'Negociações',
          href: [sellerPath, 'school-management', 'institution-contracts'].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>work</Mui.Icon>,
          invisible:
            (!sellerPermissions?.includes('school_management.can_manage_contracts') &&
              !sellerPermissions?.includes('school_management.can_view_contracts')) ||
            !isSellerInstitution,
        },
        {
          title: 'Relatórios',
          href: [sellerPath, 'school-management', ''].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>insert_chart</Mui.Icon>,
          isStaff: true,
          invisible: !isSellerInstitution || !isStaff,
        },
        {
          title: 'Configurações',
          icon: newProps => <Mui.Icon {...newProps}>settings_applications_icon</Mui.Icon>,
          items: [
            {
              title: 'Geral',
              href: [sellerPath, 'school-management', 'configurations'].join('/'),
              icon: newProps => <Mui.Icon {...newProps}>tune</Mui.Icon>,
              invisible: !sellerPermissions?.includes(
                'school_management.can_manage_configurations',
              ),
            },
            {
              title: 'Séries',
              href: [sellerPath, 'school-management', 'configurations', 'grades'].join('/'),
              icon: newProps => <Mui.Icon {...newProps}>groups</Mui.Icon>,
              invisible: !sellerPermissions?.includes(
                'school_management.can_manage_configurations',
              ),
            },
            {
              title: 'Régua de Cobrança',
              href: [sellerPath, ''].join('/'),
              icon: newProps => <Mui.Icon {...newProps}>straighten</Mui.Icon>,
              isStaff: true,
              invisible: !isStaff,
            },
            {
              title: 'Campanhas de Matrícula',
              href: [
                sellerPath,
                'school-management',
                'configurations',
                'institution-enrollment-templates',
              ].join('/'),
              icon: newProps => <Mui.Icon {...newProps}>recent_actors_outline</Mui.Icon>,
              invisible:
                !sellerPermissions?.includes('school_management.can_manage_configurations') &&
                !sellerPermissions?.includes('school_management.can_view_configurations'),
            },
            {
              title: 'Modelos de Negociação',
              href: [
                sellerPath,
                'school-management',
                'configurations',
                'institution-contract-templates',
              ].join('/'),
              icon: newProps => <Mui.Icon {...newProps}>work_outline</Mui.Icon>,
              invisible:
                !sellerPermissions?.includes('school_management.can_manage_contracts') &&
                !sellerPermissions?.includes('school_management.can_view_contracts'),
            },
          ].filter(item => !item.invisible),
          invisible:
            (!sellerPermissions?.includes('school_management.can_manage_configurations') &&
              !sellerPermissions?.includes('school_management.can_view_configurations')) ||
            !isSellerInstitution,
        },
      ].filter(item => !item.invisible),
      isNew: true,
    },
    {
      subheader: 'Relacionamentos',
      items: [
        {
          title: 'Clientes',
          href: [sellerPath, 'customers'].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>contact_mail</Mui.Icon>,
          invisible: !sellerPermissions?.includes('cx.customers'),
        },
        {
          title: 'Parcerias',
          href: [sellerPath, 'partnerships'].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>diversity_1</Mui.Icon>,
          invisible: !sellerPermissions?.includes('partnerships.partnerships'),
        },
      ].filter(item => !item.invisible),
    },
    {
      subheader: 'Loja',
      items: [
        {
          title: 'Sites',
          href: [sellerPath, 'sites'].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>web</Mui.Icon>,
          invisible: !sellerPermissions?.includes('shops.sites'),
        },
        {
          title: 'Canais',
          href: [sellerPath, 'channels'].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>store_front</Mui.Icon>,
          invisible: !sellerPermissions?.includes('shops.channels'),
        },
        {
          title: 'Solicitações de acesso',
          href: [sellerPath, 'channels-access-request'].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>co_present</Mui.Icon>,
          invisible: !sellerPermissions?.includes('cx.customers'),
        },
        {
          title: 'Concierge',
          href: [sellerPath, 'concierge'].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>add_shopping_cart_outlined</Mui.Icon>,
          invisible: !sellerPermissions?.includes('cx.concierge'),
        },
        {
          title: 'Alocações',
          href: [sellerPath, 'items'].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>inventory</Mui.Icon>,
          invisible: !sellerPermissions?.includes('shops.allocations'),
        },
        {
          title: 'Destaques',
          icon: newProps => <Mui.Icon {...newProps}>stars</Mui.Icon>,
          items: [
            {
              title: 'Destaque',
              href: [sellerPath, 'featured'].join('/'),
              icon: newProps => <Mui.Icon {...newProps}>star</Mui.Icon>,
            },
            {
              title: 'Modelos de Destaque',
              href: [sellerPath, 'featured-template'].join('/'),
              icon: newProps => <Mui.Icon {...newProps}>star_border</Mui.Icon>,
            },
          ].filter(item => !item.invisible),
          isNew: true,
          invisible: !sellerPermissions?.includes('shops.featured'),
        },
      ].filter(item => !item.invisible),
    },
    {
      subheader: 'Promoções',
      items: [
        {
          title: 'Listas',
          href: [sellerPath, 'ranges'].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>list_alt</Mui.Icon>,
          invisible: !sellerPermissions?.includes('promotions.ranges'),
        },
        {
          title: 'Ofertas',
          href: [sellerPath, 'offers'].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>featured_video_outlined</Mui.Icon>,
          invisible: !sellerPermissions?.includes('promotions.offers'),
        },
        {
          title: 'Cupons',
          href: [sellerPath, 'vouchers'].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>loyalty_outlined</Mui.Icon>,
          invisible: !sellerPermissions?.includes('promotions.vouchers'),
        },
      ].filter(item => !item.invisible),
    },
    {
      subheader: 'Configurações',
      items: [
        {
          title: 'Mensalidades',
          href: [sellerPath, 'tuition'].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>event</Mui.Icon>,
          invisible: !sellerPermissions?.includes('pricing.tuition_templates'),
        },
        {
          title: 'Contratos',
          href: [sellerPath, 'contract'].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>date_range</Mui.Icon>,
          invisible: !sellerPermissions?.includes('pricing.contract_templates'),
        },
        {
          title: 'Assinaturas',
          href: [sellerPath, 'subscriptions'].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>subscriptions_outlined</Mui.Icon>,
          disabled: true,
          invisible: true,
        },
        {
          title: 'Logística',
          href: [sellerPath, 'logistics'].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>local_shipping</Mui.Icon>,
          invisible: !sellerPermissions?.includes('logistics.warehouses'),
        },
        {
          title: 'Grupos de faturamento',
          href: [sellerPath, 'billing-groups'].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>price_change</Mui.Icon>,
          invisible: !sellerPermissions?.includes('orders.orders'),
        },
      ].filter(item => !item.invisible),
    },
    {
      subheader: 'Catálogo',
      items: [
        {
          title: 'Produtos e serviços',
          href: [sellerPath, 'products'].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>school_outlined</Mui.Icon>,
          invisible: !sellerPermissions?.includes('catalog.products'),
        },
        {
          title: 'Complementos',
          href: [sellerPath, 'options'].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>ballot</Mui.Icon>,
          invisible: !sellerPermissions?.includes('catalog.products'),
        },
        {
          title: 'Sugestões',
          href: [sellerPath, 'suggestions'].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>import_contacts</Mui.Icon>,
          invisible: !sellerPermissions?.includes('catalog.suggestions'),
        },
        {
          title: 'Preços',
          href: [sellerPath, 'prices'].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>money_outlined</Mui.Icon>,
          invisible: !sellerPermissions?.includes('pricing.prices'),
        },
        {
          title: 'Marcas',
          href: [sellerPath, 'brands'].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>label_outlined</Mui.Icon>,
          invisible: !sellerPermissions?.includes('catalog.brands'),
        },
        {
          title: 'Classe de Produtos',
          href: [sellerPath, 'product-classes'].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>request_quote</Mui.Icon>,
          invisible: !sellerPermissions?.includes('catalog.product_classes'),
        },
      ].filter(item => !item.invisible),
    },
    {
      subheader: 'Outros',
      items: [
        {
          title: 'Formulários',
          href: [sellerPath, 'forms'].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>article</Mui.Icon>,
          isNew: true,
          invisible:
            !sellerPermissions?.includes('forms.can_manage_forms') &&
            !sellerPermissions?.includes('forms.can_view_forms'),
        },
        {
          title: 'Assinatura Digital',
          icon: newProps => <Mui.Icon {...newProps}>document_scanner</Mui.Icon>,
          items: [
            {
              title: 'Documentos',
              href: [sellerPath, 'sign', 'envelopes'].join('/'),
              invisible: !sellerPermissions?.includes('sign.sign'),
            },
            {
              title: 'Modelos de Documentos',
              href: [sellerPath, 'sign', 'envelope-templates'].join('/'),
              invisible: !sellerPermissions?.includes('sign.sign'),
            },
          ].filter(item => !item.invisible),
          isNew: true,
          invisible: !sellerPermissions?.includes('sign.sign'),
        },
        {
          title: 'Integrações',
          href: [sellerPath, 'integrations'].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>extension_outlined</Mui.Icon>,
          invisible: !sellerPermissions?.includes('integrations.integrations'),
        },
        {
          title: 'Power-ups',
          href: [sellerPath, 'power-ups'].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>flash_on_outlined</Mui.Icon>,
          invisible: !sellerPermissions?.includes('integrations.powerups'),
        },
        {
          title: 'Dados',
          href: [sellerPath, 'jobs'].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>cached_outlined</Mui.Icon>,
          invisible: true,
        },
      ].filter(item => !item.invisible),
      invisible:
        !sellerPermissions?.includes('forms.can_manage_forms') &&
        !sellerPermissions?.includes('forms.can_view_forms') &&
        !sellerPermissions?.includes('sign.sign') &&
        !sellerPermissions?.includes('integrations.integrations') &&
        !sellerPermissions?.includes('integrations.powerups'),
    },
  ].filter(item => !item.invisible);

  return (
    <DefaultNavBar
      onMobileClose={onMobileClose}
      openMobile={openMobile}
      items={items}
      profile={profile}
      {...restProps}
    />
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  sellerPermissions: PropTypes.array,
};

export default NavBar;
