import * as FormAddress from './FormAddress';
import * as FormAuthentication from './FormAuthentication';
import * as FormBankAccount from './FormBankAccount';
import * as FormBilling from './FormBilling';
import * as FormBillingGroup from './FormBillingGroup';
import * as FormBrand from './FormBrand';
import * as FormCarrier from './FormCarrier';
import * as FormCfop from './FormCfop';
import * as FormChannel from './FormChannel';
import * as FormCollection from './FormCollection';
import * as FormCompany from './FormCompany';
import * as FormConcierge from './FormConcierge';
import * as FormContractTemplate from './FormContractTemplate';
import * as FormCreditCard from './FormCreditCard';
import * as FormCustomer from './FormCustomer';
import * as FormDelivery from './FormDelivery';
import * as FormDock from './FormDock';
import * as FormEnrollment from './FormEnrollment';
import * as FormEnrollmentRequest from './FormEnrollmentRequest';
import * as FormFaq from './FormFaq';
import * as FormFeatured from './FormFeatured';
import * as FormFinancialWithdrawal from './FormFinancialWithdrawal';
import * as FormForm from './FormForm';
import * as FormInstitution from './FormInstitution';
import * as FormInstitutionProfile from './FormInstitutionProfile';
import * as FormInstitutionShipment from './FormInstitutionShipment';
import * as FormItem from './FormItem';
import * as FormNote from './FormNote';
import * as FormOrder from './FormOrder';
import * as FormPage from './FormPage';
import * as FormPartnership from './FormPartnership';
import * as FormPayment from './FormPayment';
import * as FormPaymentMethod from './FormPaymentMethod';
import * as FormPeopleAuthorization from './FormPeopleAuthorization';
import * as FormPlannedSales from './FormPlannedSales';
import * as FormPowerUp from './FormPowerUp';
import * as FormPrice from './FormPrice';
import * as FormProduct from './FormProduct';
import * as FormProductClasses from './FormProductClasses';
import * as FormProductOption from './FormProductOption';
import * as FormPromotion from './FormPromotion';
import * as FormPublicMediaLibrary from './FormPublicMediaLibrary';
import * as FormRegistration from './FormRegistration';
import * as FormSalesAgreement from './FormSalesAgreement';
import * as FormSchoolManagement from './FormSchoolManagement';
import * as FormSellerPolicy from './FormSellerPolicy';
import * as FormShowcase from './FormShowcase';
import * as FormSign from './FormSign';
import * as FormSite from './FormSite';
import * as FormSiteArticle from './FormSiteArticle';
import * as FormSiteContact from './FormSiteContact';
import * as FormSiteMenu from './FormSiteMenu';
import * as FormSiteSection from './FormSiteSection';
import * as FormSiteLink from './FormSiteLink';
import * as FormStore from './FormStore';
import * as FormStudent from './FormStudent';
import * as FormSupport from './FormSupport';
import * as FormTag from './FormTag';
import * as FormTuitionTemplate from './FormTuitionTemplate';
import * as FormUser from './FormUser';

export {
  FormAddress,
  FormAuthentication,
  FormBankAccount,
  FormBilling,
  FormBillingGroup,
  FormBrand,
  FormCarrier,
  FormCfop,
  FormChannel,
  FormCollection,
  FormCompany,
  FormConcierge,
  FormContractTemplate,
  FormCreditCard,
  FormCustomer,
  FormDelivery,
  FormDock,
  FormEnrollment,
  FormEnrollmentRequest,
  FormFaq,
  FormFeatured,
  FormFinancialWithdrawal,
  FormForm,
  FormInstitution,
  FormInstitutionProfile,
  FormInstitutionShipment,
  FormItem,
  FormNote,
  FormOrder,
  FormPage,
  FormPartnership,
  FormPayment,
  FormPaymentMethod,
  FormPeopleAuthorization,
  FormPlannedSales,
  FormPowerUp,
  FormPrice,
  FormProduct,
  FormProductClasses,
  FormProductOption,
  FormPromotion,
  FormPublicMediaLibrary,
  FormRegistration,
  FormSalesAgreement,
  FormSchoolManagement,
  FormSellerPolicy,
  FormShowcase,
  FormSign,
  FormSite,
  FormSiteArticle,
  FormSiteContact,
  FormSiteMenu,
  FormSiteSection,
  FormSiteLink,
  FormStore,
  FormStudent,
  FormSupport,
  FormTag,
  FormTuitionTemplate,
  FormUser,
};
