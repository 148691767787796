import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

// Components
import { Mui } from 'components';
import { Confirmation } from '../Dialogs';

const useStyles = Mui.makeStyles(theme => ({
  root: { borderColor: theme.palette.primary.main },
  icon: { backgroundColor: theme.palette.primary.main },
}));

function List({ actions, children, loading, open, setOpen }) {
  // Hooks
  const classes = useStyles();

  return (
    <Mui.Paper variant="outlined" className={classes.root}>
      <Mui.List>
        {actions.map(action => (
          <Fragment key={action.key}>
            <Mui.ListItem button onClick={() => setOpen(action.key)}>
              <Mui.ListItemAvatar>
                <Mui.Avatar className={classes.icon}>
                  <Mui.Icon>{action.icon}</Mui.Icon>
                </Mui.Avatar>
              </Mui.ListItemAvatar>
              <Mui.ListItemText primary={action.label} secondary={action.instructions} />
            </Mui.ListItem>
            <Confirmation
              title={action.label}
              open={open === action.key}
              loading={loading}
              onClose={() => setOpen('')}
              onConfirm={action.submit}>
              {action.confirm}
              {children}
            </Confirmation>
          </Fragment>
        ))}
      </Mui.List>
    </Mui.Paper>
  );
}

List.propTypes = {
  actions: PropTypes.array,
  children: PropTypes.any,
  loading: PropTypes.bool,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default List;
